import React, { useEffect, useState } from "react";
import { getValue } from "../../utils/externalFunc";
import { Autocomplete, ListItemText, TextField } from "@mui/material";

export default function FormAutocomplete(props) {

    const [cities, setCities] = useState(false)
    const [autoOption, setAutoOption] = useState("")
    const selectValue = getValue(props.store, props.formFieldDetails.object_path)
    const [selected, setSelected] = useState()

    useEffect(() => {
        selected && selected.ID && props.updateFormValues(selected.ID, props.formFieldDetails.object_path)
    }, [selected])

    useEffect(() => {
        // console.log(props.errorsObjReady)
        props.citiesArray.length > 0 && setCities(props.citiesArray)
        props.citiesArray.length > 0 && props.citiesArray.map((option) => {if(option.ID === selectValue){ setAutoOption(option) }})
    }, [props.citiesArray])



    return  <>
            <label htmlFor={props.keyName} className={`${props.accordionItem.id !== 1 ? "min-100" : ""} form-label w-100 ${props.keyName}`}>{props.item.IsRequired ? "*" : ""}{props.item.Title}
                
                    <Autocomplete
                        disablePortal
                        freeSolo
                        clearOnEscape
                        size="small"   
                        value={autoOption}
                        options={cities || []}
                        getOptionLabel={(options) => options?.Title || ""}
                        sx={{ width: "100%" }}
                        onChange={(e, newValue) => {newValue && setSelected(newValue)}}
                        renderInput={(params) => (
                            <div className="input-group input-group-sm mb-3"> 
                                <TextField                                   
                                    value={selected?.Title} 
                                    {...params}
                                    error={Boolean(props.formErrors[props.keyName]) || props.formErrors[props.keyName] === ""}
                                    helperText={(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") ? props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}` : ""}
                                    />  
                                {/* <div className="invalid-feedback">{props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}`}</div> */}
                            </div>)}
                        renderOption={(props, item) => (
                            <li {...props} key={item.ID}>
                              <ListItemText>{item.Title}</ListItemText>
                            </li>)}
                     />      
                  
            </label>
            
            </>
}