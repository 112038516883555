import { createSlice } from '@reduxjs/toolkit';
import { getLocalStoragKey, setLocalStorage } from '../utils/YamatLocalStorage';

const localStorageActiveStep = getLocalStoragKey('ymt_assos', 'activeStep');
const localStorageformVals = getLocalStoragKey('ymt_assos', 'formVals');
const localStorageActivityTypes = getLocalStoragKey('ymt_assos', 'activityTypes');
// const localStorageFormFieldsForValidation = getLocalStoragKey('ymt_assos', 'formFieldsForValidation');

const formInitialState = {
  "ProjectDetails": {
    "FieldId": 0,
    "FormComment": "",
    "Description": "",
    "FromTime": "",
    "ToTime": "",
    "VolunteersNum": "",
    "MaxVolunteersPerShift": "",
    "ActivityOrganizer": "",
    "ActivityParticipantsNum": "",
    "ParticipantsAge": "",
    "ParticipantsDescription": "",
    "ProjectLogistics": {
      "IsRenewalPainting": false,
      "IsWallPainting": false,
      "IsGardening": false,
      "WallpaintHeight": "",
      "WallpaintWidth": "",
      "GardeningIds": [],
      "GardeningAreaSqm": "",
      "FacilitiesColours": [],
      "FacilitiesNum": "",
      "FenceColours": [],
      "FenceLength": "",
      "WallRenewColour": "",
      "WallSqmTotal": "",
      "WindowColours": "",
      "WindowsNum": "",
      "RailingColours": "",
      "RailingLength": "",
      "GratesColours": "",
      "GratesAreaSqm": "",
      "DoorsColours": "",
      "DoorsNum": ""
    }
  },
  "Contact": {
    "ContactID": 0,
    "ContactSetID": 0,
    "LastName": "",
    "FirstName": "",
    "Phone": "",
    "Position": "",
    "Email": ""
  },
  "Place": {
    "ID": "00000000-0000-0000-0000-000000000000",
    "CityID": 0,
    "PopulationID": 0,
    "IsNewsLetter": true,
    "OrganizationName": "",
    "PlaceDescription": "",
    "Branch": "",
    "HouseNum": "",
    "Street": ""
  }
}

export const YamatReducer = createSlice({
  name: 'reducer',
  initialState: {
    activeStep: localStorageActiveStep ? localStorageActiveStep : 0,
    formVals: localStorageformVals ? localStorageformVals : formInitialState,
    formMemoryObj: {},
    contactMemoryObj: {},
    activity: 0,
    activityTypes: localStorageActivityTypes ? localStorageActivityTypes : [],
    standbyPlaceId: "00000000-0000-0000-0000-000000000000",
    formFieldsForValidation: [],
    switchArrayKeys: {}
  },
  reducers: {
    clearFormVals: (state, action) => {
      state.formVals = formInitialState
      setLocalStorage('ymt_assos', state)
    },
    clearFormValsForAnotherActivity: (state, action) => {
      let PlaceID = state.formVals.Place.ID
      let obj = {...state.formVals, ProjectDetails: formInitialState.ProjectDetails, Place: formInitialState.Place}
      let resetedFormVals = {...obj, Place: {...obj.Place, ID: PlaceID}}
      state.formVals = resetedFormVals
      state.formMemoryObj = {}
      setLocalStorage('ymt_assos', state)
    },
    setStandbyPlaceId: (state, action) => {
      const { payload } = action
      state.standbyPlaceId = payload
      setLocalStorage('ymt_assos', state)
    },
    setSwitchArrayKeys: (state, action) => {
      const { payload } = action
      state.switchArrayKeys = payload
    },
    setFieldId: (state, action) => {
      const { payload } = action
      state.formVals = {...state.formVals, ProjectDetails: {...state.formVals.ProjectDetails, FieldId: Number(payload)}}
      let fieldId = payload
      if(String(fieldId) in state.formMemoryObj) {
        //state.formMemoryObj[fieldId] = payload
      }else{
        state.formMemoryObj[fieldId] = {...formInitialState, ProjectDetails: {...formInitialState.ProjectDetails, FieldId: fieldId}}
      }
      console.log("activity updated")
    },
    setFormVals: (state, action) => {
      const { payload } = action
      state.formVals = payload
      let fieldId = payload.ProjectDetails.FieldId
      if(String(fieldId) in state.formMemoryObj) {
        state.formMemoryObj[fieldId] = payload
      }
      setLocalStorage('ymt_assos', state)
    },
    updateContactMemoryObj: (state, action) => {
      const { payload } = action
      state.contactMemoryObj[payload.key] = payload.value
      setLocalStorage('ymt_assos', state)
    },
    setFormActiveStep: (state, action) => {
      const { payload } = action
      state.activeStep = payload
      setLocalStorage('ymt_assos', state)
    },
    setActivity: (state, action) => {
      const { payload } = action
      state.activity = payload
      setLocalStorage('ymt_assos', state)
    },
    setActivityTypes: (state, action) => {
      const { payload } = action
      state.activityTypes = payload
      setLocalStorage('ymt_assos', state)
    },
    setFormFieldsForValidation: (state, action) => {
      const { payload } = action
      state.formFieldsForValidation = payload
      setLocalStorage('ymt_assos', state)
    }
  }
});

export const {  setFleg, setFormExtraVals, clearFormVals,
                setFormVals, setFormActiveStep, setActivity, setActivityTypes, setFormFieldsForValidation,
                setStandbyPlaceId, clearFormValsForAnotherActivity, setSwitchArrayKeys, updateContactMemoryObj,
                setFieldId }  = YamatReducer.actions

export default YamatReducer.reducer;
