import React from "react";
import './Footer.css'
import LogoIcon from "../../assets/svg/logo";
import ArisonIcon from "../../assets/svg/_arison";

export default function Footer(props) {
    
    return  <footer className="Footer py-3 px-4 w-100">
              {/* <LogoIcon/>
              <span className="ms-3">Powerd by Ruach Tova</span> */}
              <div className="links_con font-s">
                <a href="https://www.good-deeds-day.org.il/terms/">תנאי שימוש</a>
                <a href="https://www.good-deeds-day.org.il/terms/">מדיניות פרטיות</a>
                <a href="https://www.good-deeds-day.org.il/accessibility/">הצהרת נגישות</a>
              </div>
              <div className="d-flex align-items-center">
                <span className="text-color-regular mt-1 me-2">ביוזמת </span>
                <span className="arison_icon"><ArisonIcon/></span>
              </div>
            </footer>  
}