import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import ActionForm from './pages/ActionForm/ActionForm';
import Footer from './components/Footer/Footer';
import { getMainPage, getTopSectionForm } from './utils/apiUtils';
import {useDispatch, useSelector} from 'react-redux';
import {setFormActiveStep, setFormVals} from './store/YamatReducer';
import ErrorPage from './pages/404/404';
import HomePage from './pages/HomePage/HomePage';
import TagManager from 'react-gtm-module'

function App() {

  const store = useSelector(state => state.reducer)
  const [mainPageDataObj, setMainPageDataOb] = useState(false)
  const [topSectionForm, setTopSectionForm] = useState(false)
  const leadColor = "#D93C3C"
  const dispatch = useDispatch()

  let finalMount = useRef(true)
  useEffect(() => {
      if(finalMount.current){
        finalMount.current = false
        init()
      }
      TagManager.initialize({ gtmId: 'GTM-PQQCZB' })
  },[])
  
  const init = () => {
    dispatch(setFormActiveStep(0))
    getMainPage().then(res => {
      setMainPageDataOb(res.data)
    }).catch(error => {console.log(error)})

    getTopSection()
  }

  const getTopSection = () => {
    getTopSectionForm().then(res => {
      setTopSectionForm(res.data)
    })
    .catch(error => {
        console.log(error)
    })
  }

  const updateFormValsFromMemoryObject = (activity) => {
    console.log(String(activity) in store.formMemoryObj)
    console.log(store.formMemoryObj)
    console.log(store.formMemoryObj[String(activity)])
      if(store.formMemoryObj && String(activity) in store.formMemoryObj){
        let obj = {...store.formMemoryObj[String(activity)]}
        if(!(Object.keys(store.contactMemoryObj).length === 0 && store.contactMemoryObj.constructor === Object)){
          Object.keys(store.contactMemoryObj).map(key => {
            let pathArray = key.split(".")
            if(typeof(pathArray) !== "undefined" && pathArray.length > 0){
              obj = {...obj, [pathArray[0]]: { ...obj[pathArray[0]], [pathArray[1]]: store.contactMemoryObj[key]}}
            }
          })
        }
        dispatch(setFormVals(obj))
      }
  }

  useEffect(() => {
    updateFormValsFromMemoryObject(store.formVals.ProjectDetails.FieldId)
  }, [store.formVals.ProjectDetails.FieldId])

  return (
    <div className="App f-reg">
      <Header/>
        <Routes>
          <Route path='/*' element={<ErrorPage/>} />
          <Route path='/' element={<HomePage mainPageDataObj={mainPageDataObj} topSectionForm={topSectionForm} updateFormValsFromMemoryObject={updateFormValsFromMemoryObject}/>}/>
          <Route path='/action-form' element={<ActionForm mainPageDataObj={mainPageDataObj} leadColor={leadColor} topSectionForm={topSectionForm} updateFormValsFromMemoryObject={updateFormValsFromMemoryObject}/>} />
        </Routes> 
      <Footer/>
    </div>
  );
}

export default App;
