import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL

// ===== In order to work localy, use the line blow, insted of the one on top. ===== //

//const apiUrl = ""

// ===== In addition, you mast add this line to package.json, "proxy": "https://prod-ruachtova-gdd.azurewebsites.net", or "https://dev-ruachtova-gdd.azurewebsites.net"//

const URLmainPageData = `${apiUrl}/api/Organizations/GetMainPage`
const URLgetForm = `${apiUrl}/api/OrganizationsProjectForm/Get`
const URLgetFormByFieldId = `${apiUrl}/api/OrganizationsProjectForm/GetByFieldId/`
const URLgetCities = `${apiUrl}/api/Cities/Get`
const URLformValidation = `${apiUrl}/api/OrganizationsProject/Validate/`
const URLaddProject = `${apiUrl}/api/OrganizationsProject/Post` 

const getMainPage = () => {
    return axios.get(URLmainPageData)
}

const getOrganizationForm = (fieldId) => {
    return axios.get(URLgetFormByFieldId + fieldId)
}

const getTopSectionForm = () => {
    return axios.get(URLgetForm)
}

const getCitiesArray = () => {
    return axios.get(URLgetCities)
}

const apiFormValidation = (formVals) => {
    return axios.post(URLformValidation, formVals)
}

const sendFromFinal = (formVals) => {
    return axios.post(URLaddProject, formVals)
}

export { getMainPage, getOrganizationForm, getTopSectionForm, getCitiesArray, apiFormValidation, sendFromFinal }