import React from "react";
import './Header.css'
import logoIcon from "../../assets/images/logo.png";

export default function Header() {

    return  <header className="header_comp">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="header_logo" href="https://www.good-deeds-day.org.il">
                    <img src={logoIcon} alt="יום מעשים טובים"/>
                </a>
              </nav>
            </header>  
}