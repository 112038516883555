import { Box, Checkbox, Chip, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import { getValue } from "../../utils/externalFunc";

export default function FormMultipleSelect(props) {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const storeValue = getValue(props.store, props.formFieldDetails?.object_path)

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          //width: 250,
        },
      },
    };

    const handleChange = (event, path) => {
        const {
        target: { value },
        } = event;
        props.updateFormValues(
            typeof value === 'string' ? value.split(',') : value, path
        );
    };

    return  <>
            <label key={props.keyName} htmlFor={props.keyName} className={`multiple_select form-label w-100`}>{props.item.IsRequired ? "*" : ""}{props.item.Title}
                <div className="input-group input-group-sm mb-3"> 
                    <FormControl sx={{ m: 0, width: "100%" }}>                                                                  
                        <Select
                            disabled={props.disabled}
                            labelId="demo-multiple-chip-label"
                            id={`id_${props.keyName}`}
                            multiple
                            value={storeValue}
                            onChange={(e) => handleChange(e, props.formFieldDetails.object_path)}
                            input={<OutlinedInput className="rounded-0" id="select-multiple-chip" required={props.item.IsRequired} style={{ height: 31 }}/>}
                            error={(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled ? true : false}
                            renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected && selected.map((selectedVal, ind) => {
                                    return props.formFieldDetails.select_values.map(subItem => {
                                        return subItem.id === selectedVal && <Chip key={ind} label={subItem.title} />
                                    })
                                })}
                            </Box>
                            )}
                        >
                            {props.formFieldDetails.select_values.map((selectValue) => (
                            <MenuItem disabled={storeValue.length === 2 && !storeValue.includes(selectValue.id)} key={selectValue.id} value={selectValue.id}>
                                <Checkbox size="small" checked={storeValue.includes(selectValue.id)} />
                                <ListItemText primary={selectValue.title} />
                            </MenuItem>
                        ))}
                        </Select>
                        {(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled &&
                            <p className={`font-s primary_color mb-0`}>{props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}`}</p>}
                    </FormControl>                         
                    <div className="invalid-feedback">{ props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}` }</div>
                </div>
            </label>  
            </> 
}