import React from "react";
import { getValue } from "../../utils/externalFunc";

export default function FormTextarea(props) {

    return  <>
                <label htmlFor={props.keyName} className={`form-label w-100`}>{props.item.IsRequired ? "*" : ""}{props.item.Title}
                    <div className="input-group input-group-sm mb-3"> 
                        <textarea
                            disabled={props.disabled}
                            rows={6}
                            onChange={(e) => props.updateFormValues(e.target.value, props.formFieldDetails.object_path)} 
                            value={getValue(props.store, props.formFieldDetails?.object_path)}
                            className={`form-control ${(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled ? "is-invalid" : ""}`} id={props.keyName} required={props.item.IsRequired}>
                        </textarea>                             
                        <div className="invalid-feedback">{props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}`}</div>
                    </div>
                </label> 
            </>
}