import React from "react";
import { getValue } from "../../utils/externalFunc";

export default function FormSingleSelect(props) {

    let storeValue = getValue(props.store, props.formFieldDetails.object_path)

    return  <>
            <label htmlFor={props.keyName} className={`form-label w-100 ${props.keyName}`}>{props.item.IsRequired ? "*" : ""}{props.item.Title}
                <div className="input-group input-group-sm mb-3"> 
                    <select 
                        className={`form-select ${(props.formErrors[props.keyName] || props.formErrors[props.keyName] === "") && !props.disabled ? "is-invalid" : ""}`} 
                        value={storeValue}
                        onChange={(e) => props.updateFormValues(Number(e.target.value), props.formFieldDetails.object_path)}
                        id={props.keyName} required={props.item.IsRequired} 
                        aria-label="select example"
                        disabled={props.disabled}
                    >
                        <option value="">בחרו מהרשימה</option>
                        {
                        props.formFieldDetails.select_values ? props.formFieldDetails.select_values.map((subItem) => {
                                return <option key={subItem.id} value={subItem.id}>{subItem.title}</option>
                            }) : null
                        }
                    </select>
                    <div className="invalid-feedback">{props.errorsObjReady[props.keyName] || `יש למלא ${props.item.Title}`}</div>
                </div>
            </label>
            </>
}