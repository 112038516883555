import { React, useEffect, useRef, useState } from "react";
import './ActionForm.css';
import { checkArray, extractKeyName, fieldTypeDic, getValue, sortArray } from "../../utils/externalFunc";
import { useDispatch, useSelector } from "react-redux";
import { setFormFieldsForValidation } from "../../store/YamatReducer";
import FormInput from "../../components/FormParts/FormInput";
import FormSingleSelect from "../../components/FormParts/FormSingleSelect";
import { Checkbox } from "@mui/material";

export default function ContactSection(props){

    const store = useSelector(state => state.reducer)
    const dispatch = useDispatch()
    const [contact, setContact] = useState(store.formVals.Contact || {})
    const [formErrors, setFormErrors] = useState({})
    const [mainArray, setMainArray] = useState(props.stepInfo.FormSteps[1].FormFields)  

    let finalMount = useRef(true);
    useEffect(() => {
        if(finalMount.current){
            finalMount.current = false
            init()
        }
    }, [])

    const init = () => {
        let steps = checkArray(props.stepInfo.FormSteps)
        if(steps && steps.length > 1){
            let fields = checkArray(steps[1].FormFields)
            let rightCol = {col: []}
            let leftCol = {col: []}
            let final = []
            let validationArray = []
            fields.map((item, index) => {
                var formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false
                !validationArray.includes(formFieldDetails.object_path) && 
                validationArray.push(`organizationProject.${formFieldDetails.object_path}`)
                formFieldDetails.position === "right" && rightCol.col.push(item)
                formFieldDetails.position === "left" && leftCol.col.push(item)
            })
            final.push(rightCol)
            final.push(leftCol)
            setMainArray(final)
            validationArray && dispatch(setFormFieldsForValidation(validationArray))
        }
    }

    const updateContactObj = (value, path) => {
        setFormErrors({...formErrors, [path]: ""})
        //setContact({...contact, [path]: value})      
    }

    // useEffect(() => {
    //     props.setFormState(contact, "Contact")
    // }, [contact])

    useEffect(() => {
        setFormErrors(props.errorsObjReady)
    }, [props.errorsObjReady])

    // useEffect(() => {
    //     let arr = sortArray(props.stepInfo.FormFields)
    //     setMainArray(arr)
    // }, [props.stepInfo.FormFields])
      
    return  <>  <div className="ContactSection my-4 my-md-5">
                    {/* <h2 className="font-xl text-center primary_color f-bold">{props.stepInfo.Title}</h2> */}
                </div>
                <form action="" id="ContactSection_form" className={`px-3 px-md-0 ContactSection_form needs-validation ${props.validation === "start" ? "" : "was-validated"}`} noValidate> {/** was-validated */}

                    <div className="row gx-md-5">
                        {
                            mainArray?.map((col, index) => {
                                return  <div key={index} className="col-md-6 col-12">
                                      
                                        {
                                            col.col?.map(item => {
                                                var formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false 
                                                var keyName = extractKeyName(formFieldDetails.object_path)
                                                if(item.FormFieldID !== 81){ {/** not equal to checkbox */}
                                                    return(
                                                        <div key={item.FormFieldID}>
                                                            {formFieldDetails.section_title && <p className="text-color-regular f-bold">{formFieldDetails.section_title}</p>}
                                                            {/* {formFieldDetails.section_subtitle && <p className="primary_color mb-0 font-s">{formFieldDetails.section_subtitle}</p>} */}
                                                            {fieldTypeDic[item.FormFieldType] === 'SelectSingle' ?                                                   
                                                                <FormSingleSelect keyName={keyName} item={item} store={store} updateFormValues={props.updateFormValues} errorsObjReady={props.errorsObjReady} formErrors={formErrors} formFieldDetails={formFieldDetails}/>
                                                                :<FormInput 
                                                                    keyName={keyName} 
                                                                    item={item} 
                                                                    store={store} 
                                                                    updateFormValues={props.updateFormValues} 
                                                                    errorsObjReady={props.errorsObjReady} 
                                                                    formErrors={formErrors} 
                                                                    formFieldDetails={formFieldDetails} 
                                                                    disabled={store.formVals.Place.ID !== "00000000-0000-0000-0000-000000000000" && formFieldDetails.object_path.includes("OrganizationName")}
                                                                />
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })    
                                        }

                                        </div>

                            })
                        }
                        {
                            mainArray?.map((col, index) => {
                                return  <div key={index} className="col-12">
                                      
                                        {
                                            col.col?.map(item => {
                                                var formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false 
                                                var keyName = extractKeyName(formFieldDetails.object_path)
                                                if(item.FormFieldID === 81){ {/** equal to checkbox */}
                                                    return  <div key={keyName}>
                                                                <div className="form-check">
                                                                    <input className={`form-check-input ${(formErrors[props.keyName] || formErrors[props.keyName] === "") && !props.disabled ? "is-invalid" : ""}`} 
                                                                        type="checkbox" 
                                                                        checked={getValue(store, formFieldDetails?.object_path)} 
                                                                        onChange={(e) => {props.updateFormValues(e.target.checked, formFieldDetails.object_path)}}
                                                                        id="flexCheckDefault"
                                                                    />
                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                    {item.Title}
                                                                    </label>
                                                                    <div id="invalidCheck3Feedback" className="invalid-feedback">
                                                                        {props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}
                                                                    </div>
                                                                </div>
                                                                {/* {(formErrors[keyName] || formErrors[keyName] === "") && <p className="primary_color font-s">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</p>} */}
                                                            </div>
                                                }
                                            })    
                                        }

                                        </div>

                            })
                        }
                    </div>
                </form></>
}