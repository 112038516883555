import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {setFieldId, setFormVals, updateFormMemoryObj} from "../store/YamatReducer";

const CustomSwitchBox = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        left: 11,
        content: '"לא"',
        color: '#fff',
        fontSize: 13,
        top: 19,
      },
      '&:after': {
        content: '"כן"',
        right: 15,
        color: '#fff',
        fontSize: 13,
        top: 19,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff'
    },
    '& .MuiSwitch-switchBase:after': {
        content:'"לא"',
        color: 'gray',
        position: 'absolute',
        fontSize: 13,
        top: 11,
        right: 12.5
    },
    '& .MuiSwitch-switchBase.Mui-checked:after': {
        content:'"כן"',
        color: '#d93c3c',
        right: 14
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
        backgroundColor: '#d93c3c',
        opacity: 1
    }
  }));

  const fieldTypeDic = {
    1: 'checkbox',
    2: 'number',
    3: 'SelectSingle',
    4: 'SelectMultiple',
    5: 'text',
    6: 'ButtonGroup',
    7: 'ButtonSubmit',
    8: 'textarea',
    9: 'tel',
    10: 'email',
    11: 'Date',
    12: 'Time',
    13: 'DisplaySwitchSingle',
    15: 'SelectAutoComplete'
}

const getTableRowsReady = (obj) => {

  let finalRows = []
  let contactFilterValues = [""];
  let populationTypeFilterValues = [""];
  let hoursFilterValues = [""];
  let activityTypeFilterValues = [""];
  let participateNumberFilterValues = [""];

  obj?.map(item => {
      let row = {}
      item.GddColumnsData?.map(subItem => {

        if(subItem.GddColumnHeaderID === 1){ row.id = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 2){ row.projectName = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 3){ row.associationName = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 4){ row.creationDate = subItem.DisplayData }
        if(subItem.GddColumnHeaderID === 5){ row.contact = subItem.DisplayData; contactFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 6){ row.populationType = subItem.DisplayData; populationTypeFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 7){ row.hours = subItem.DisplayData; hoursFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 8){ row.activityType = subItem.DisplayData; activityTypeFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 9){ row.participateNumber = subItem.DisplayData; row.participateNumberTitle = subItem.Title; participateNumberFilterValues.push(subItem.DisplayData) }
        if(subItem.GddColumnHeaderID === 10){ row.madeForCompany = subItem.DataIds[0] }

      })
      finalRows.push(row)
  })

  // console.log(finalRows)
  contactFilterValues = contactFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  populationTypeFilterValues = populationTypeFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  hoursFilterValues = hoursFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  activityTypeFilterValues = activityTypeFilterValues.filter((value, index, array) => array.indexOf(value) === index);
  participateNumberFilterValues = participateNumberFilterValues.filter((value, index, array) => array.indexOf(value) === index);

  let finalFilters = {
    contact: contactFilterValues,
    populationType: populationTypeFilterValues,
    hours: hoursFilterValues,
    activityType: activityTypeFilterValues,
    participateNumber: participateNumberFilterValues
  }
  return {finalRows: finalRows, finalFilters: finalFilters}
}

const extractKeyName = (path) => {
  if(path && path.includes(".")){
      let pathArr = path.split(".")
      return pathArr[pathArr.length - 1]
  }else{
      return path
  }
}

const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const sortArray = (arr) => {
  arr.sort(function(a, b) {
    var keyA = a.Sort,
      keyB = b.Sort
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  return arr
}

const stepsDic = {
  ActivitySection_form: 0,
  ContactSection_form: 1,
  SuccessFormSection: 2
}

const sendToLoginPage = (locationIdent) => {
  console.log('redirected to login page')
  window.location.href = `/${locationIdent}/login`
}

const checkArray = (array) => {
  return array && typeof(array) !== "undefined" &&
  Array.isArray(array) &&
  array.length > 0 && array
}

const muiProLicenseKey = "6576263f0a482cd2aa22962a213ce203Tz03Njk0MCxFPTE3MjkzMzQ5NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="

const handleFormData = (data, firstStepTitle) => {
  let switchArrayKeys = {IsGardening: [], IsWallPainting: [], IsRenewalPainting: []}
  let array = [{id: 1, StepTitle: firstStepTitle, FormFields:[]}]
  let counter = 2;
  let innerColRight = {col: []}
  let innerColLeft = {col: []}
  let firstTab = data.find(item => item.FormFieldType === 16)
  let firstTabArray = []
  if(firstTab){
    let formFieldDetails = getJsonStringDetails(firstTab.FormFieldDetails)
    if(formFieldDetails && 'accordion_ids' in formFieldDetails){
      firstTabArray = formFieldDetails.accordion_ids
    }
  }
  let validationArray = []
  data?.map(item => {
    let formFieldDetails = getJsonStringDetails(item.FormFieldDetails)
    if(formFieldDetails && firstTabArray && firstTabArray?.includes(item.FormFieldID)){
      !validationArray.includes(formFieldDetails.object_path) && 
      validationArray.push(`organizationProject.${formFieldDetails.object_path}`)
      formFieldDetails.position === "right" && innerColRight.col.push(item)
      formFieldDetails.position === "left" && innerColLeft.col.push(item)
    }

    if(item.FormFieldType === 13 && formFieldDetails){
      let switchKey = formFieldDetails.identifier
      let innerObj = {id: counter, StepTitle: item.Title, StepSubTitle: formFieldDetails.subtitle, object_path: formFieldDetails.object_path, FormFields: []}
      let row = []
      let idsArray = formFieldDetails && "switch_ids" in formFieldDetails && formFieldDetails['switch_ids']
      let dataIdsArray = data.filter(x =>  idsArray.includes(x.FormFieldID))
      let lastRowNum = 1
      dataIdsArray?.map((subItem, index) => {
        let innerFormFieldDetails = subItem.FormFieldDetails && isJsonString(subItem.FormFieldDetails) ? JSON.parse(subItem.FormFieldDetails) : false  
        switchArrayKeys[switchKey].push(innerFormFieldDetails.object_path)
        !validationArray.includes(innerFormFieldDetails.object_path) &&
        validationArray.push(`organizationProject.${innerFormFieldDetails.object_path}`)
        if(innerFormFieldDetails.row === lastRowNum){
          let col = {col: []}
          col.col.push(subItem)
          row.push(col)
          index === dataIdsArray.length - 1 && innerObj.FormFields.push(row)
        }else{
          innerObj.FormFields.push(row)
          row = []
          let col = {col: []}
          col.col.push(subItem)
          row.push(col)
          lastRowNum = innerFormFieldDetails.row
        }  
      })
      array.push(innerObj)
      counter++
    }
    
  })
    let firstTabContainer = []
    firstTabContainer.push(innerColRight)
    firstTabContainer.push(innerColLeft)
    array[0].FormFields.push(firstTabContainer)
  //console.log("final ", array)
  return {final: array, validationArray: validationArray, switchArrayKeys: switchArrayKeys}
}

const getJsonStringDetails = (str) => {
  return str && isJsonString(str) ? JSON.parse(str) : false
}

const getValue = (store, path) => {
  let pathArray = path?.split(".")
  if(typeof(pathArray) !== "undefined" && pathArray.length > 0){
      if(pathArray.length === 2){
          return store.formVals[pathArray[0]][pathArray[1]]
      }else if(pathArray.length === 3){
          return store.formVals[pathArray[0]][pathArray[1]][pathArray[2]]
      }
  }else{
      return path
  }
}

const updateActivity = (dispatch, activity) => {
    dispatch(setFieldId(activity))
}
 
export { CustomSwitchBox, fieldTypeDic, getTableRowsReady, muiProLicenseKey, extractKeyName, isJsonString, sortArray,
         stepsDic, sendToLoginPage, checkArray, handleFormData, getValue, getJsonStringDetails, updateActivity }