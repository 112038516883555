import React, { useEffect, useState } from "react";
import './NoticePopup.css'
import { Modal } from "react-bootstrap";
import CloseCircleIcon from "../../assets/svg/closeCircle";
import { useSelector } from "react-redux";
import NoticeIcon from "../../assets/svg/_noticeIcon";

export default function NoticePopup(props) {

  const store = useSelector(state => state.reducer)
  const [activeCloseBtn, setActiveCloseBtn] = useState(false)
  const [selectedOption, setSelectedOption] = useState(false)


  const init = () => {
        let option = store.activityTypes?.find(x => x.id === Number(props.selectedActivity))
        option && setSelectedOption(option)
  }

  useEffect(() => {
    init()
  }, [props.selectedActivity])
  
    return  <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.modalShow}
                onHide={() => {console.log('hide')}}
            >
                <div className='f-reg px-4 pb-4 pt-2 mb-3 relative notic_popup'>
                    <div className='position-absolute close_circle_icon pointer' onClick={() => props.setModalShow(false)} onMouseLeave={() => setActiveCloseBtn(false)} onMouseEnter={() => setActiveCloseBtn(true)}><CloseCircleIcon active={activeCloseBtn} /></div>
                        <div className="f-bold text-disabled font-xl mt-2 pt-1 mb-2 mb-md-3 gray_section_popup">
                            <span className="me-2 ms-0 mb-2 mb-md-0"><NoticeIcon/></span>
                            <span>חשוב לדעת</span>
                        </div>
                        <div className="px-0 px-md-4">
                            <h1 className='text-start mt-3 mt-md-4 mb-1 mb-md-4 pb-2 primary_color pop_title'><div className="f-bold me-2">שינוי הפעילות ל{selectedOption.title} הינכם נדרשים להכין את הדברים הבאים:</div></h1>
                            <p className="pre-wrap">{selectedOption?.select_details?.description}</p>
                        </div>

                        <div className={`d-flex justify-content-center mt-4 pt-3`}>
                            <button type='button' onClick={() => props.updateGlobalActivity()} className={`f-bold btn btn-primary text-center popup_btn`}>הבנתי, תודה</button>
                        </div>
                       
                    
                </div>
            </Modal> 
}