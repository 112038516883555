import { React, useEffect, useState } from "react";
import './HomePage.css';
import NoticeIcon from "../../assets/svg/_noticeIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActivityTypes, setFormActiveStep, setFormVals } from "../../store/YamatReducer";
import {checkArray, extractKeyName, updateActivity} from "../../utils/externalFunc";

export default function HomePage(props){

    const store = useSelector(state => state.reducer)
    const [activity, setActivity] = useState(0)
    const [validationProccess, setValidationProccess] = useState(false)
    const [selectInfoArray, setSelectInfoArray] = useState(false)
    const [selectedOption, setSelectedOption] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const validateForm = () => {
        let form = document.getElementById("home_page_form")
        setValidationProccess(true)
        if(form.checkValidity()){
            navigate("/action-form")
        }
    }

    const showActivityDetails = () => {
        let option = store.activityTypes?.find(x => x.id === Number(activity))
        option && setSelectedOption(option)
    }

    useEffect(() => {
        updateActivity(dispatch, activity)
        activity && showActivityDetails()
    }, [activity])

    useEffect(() => {
        if(props.topSectionForm){
            let selectInfo = checkArray(props.topSectionForm.FormSteps)
            selectInfo && setSelectInfoArray(selectInfo[0].FormFields)
            try{
                let select_values = JSON.parse(selectInfo[0].FormFields[0].FormFieldDetails)
                dispatch(setActivityTypes(select_values.select_values))
            }catch(e){
                console.log("select_values -> Bad Format")
            }
            
        }
    }, [props.topSectionForm])

    useEffect(() => {
        dispatch(setFormActiveStep(0))
    }, [])
      
    return  <div className="HomePage min-height-100">  
                {props.mainPageDataObj ? <div className="section_container py-4 mb-1 px-md-0 px-4 text-center">
                    <h1 className="primary_color f-bold mb-4 pb-2 mt-md-0 mt-2">{props.mainPageDataObj.Title}</h1>
                    <h2 className="font-xxl f-bold mb-4 pb-2">{props.mainPageDataObj.SubTitle}</h2>
                    <p className="font-xl">{props.mainPageDataObj.Description}</p>
                </div> : 
                <div className="d-flex justify-content-center align-items-center min-height-100">
                    <div className="spinner-border" style={{color: props.leadColor}} role="status"></div>
                </div>}
                
                {props.mainPageDataObj && <form action="" id="home_page_form" className={`mx-3 py-4 bg-body home_page needs-validation ${validationProccess ? "was-validated" : ""}`} noValidate> {/** was-validated */}
                        
                    <div className="align-items-center d-flex flex-column section_container text-center">
                        {store.formVals.Place.ID !== "00000000-0000-0000-0000-000000000000" && 
                        <p className="primary_color mb-2 font-xxl">הזנת פעילות נוספת</p>}
                        <div className="f-bold text-disabled font-xl mt-1 mt-md-2 mb-2 mb-md-3 notice_block">
                            <span className="me-2 ms-0 mb-md-0 mb-2"><NoticeIcon/></span>
                            <span>{props.mainPageDataObj.AddProjectTitle}</span>
                        </div>
                        <p className="font-m text-disabled m-auto mb-3 mb-md-4">{props.mainPageDataObj.AddProjectSubTitle}</p>
                        {
                            selectInfoArray && selectInfoArray.map((item, index) => {
                                
                                let formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false
                                let keyName = extractKeyName(formFieldDetails.object_path)
                                return  <div key={index} className="d-flex flex-column align-items-center mb-3 px-md-0 px-3 w-100">
                                            <label htmlFor={keyName} className="form-label f-bold mb-2 font-xl">{item.Title}</label>
                                            <div className="select_con input-group input-group-sm mb-2"> 
                                                <select id={keyName} className={`form-select`} onChange={(e) => setActivity(e.target.value)} required={item.IsRequired} aria-label="select example">
                                                    <option value="">בחרו מהרשימה</option>
                                                    {
                                                    formFieldDetails.select_values ? formFieldDetails.select_values.map((subItem) => {
                                                            return <option key={subItem.id} value={subItem.id}>{subItem.title}</option>
                                                        }) : null
                                                    }
                                                </select>
                                                <div className="invalid-feedback text-start">יש למלא מה תרצו לעשות</div>
                                            </div>
                                        </div>
                            })
                        }

                                        {selectedOption && <div className="d-flex flex-column align-items-start mb-md-3 px-md-0 px-3 w-100 select_con">
                                            <div className="d-flex flex-column align-items-start text-start">
                                                <p className="f-bold">
                                                    {selectedOption.select_details.title}                                     
                                                </p>
                                                <p className="pre-wrap">{selectedOption.select_details.description}</p>                                             
                                            </div>                                      
                                        </div>}
                    </div>
                </form>}

                {props.mainPageDataObj && <div className="d-flex flex-column align-items-center my-4 py-2">    
                    <button className="btn btn-primary f-bold" onClick={() => validateForm()} >שנתחיל</button> {/** disabled={!activity} */}
                </div>}
            </div>
}