export default function ArisonIcon() {
    return  <svg xmlns="http://www.w3.org/2000/svg" width="124" height="37" viewBox="0 0 124 37" fill="none">
                <g clipPath="url(#clip0_379_3850)">
                    <path d="M123.17 20.6696C122.46 20.1396 121.72 20.7296 121.61 20.8296C121.45 20.9696 118.02 24.4496 114.24 26.6096C113.88 26.8196 113.32 26.9196 112.92 26.8796C111.76 26.7596 111.8 25.4196 111.86 24.4496C112.02 21.9596 112.3 19.8896 111.1 19.0096C110.71 18.7196 110.18 18.4596 109.56 18.4296C108.2 18.3696 107.48 18.8196 106.55 19.4996C105.46 20.2996 101.49 24.5396 101.31 24.7096C101.45 24.2196 103.17 19.9796 103.5 18.6696C103.75 17.6496 102.93 17.3996 102.65 17.3796C101.45 17.2896 100.54 18.5596 100.14 19.3596C99.1902 19.8096 96.6402 19.7696 94.8502 19.3796C93.6702 19.1296 92.9102 18.0296 92.1602 17.1596C91.8002 16.7596 91.3802 16.5496 90.8802 16.4696C86.9302 15.8396 80.6402 24.1296 79.8802 26.7596C79.2802 28.8296 80.3202 30.1696 80.6502 30.5196C81.4502 31.3696 83.1702 31.4496 83.1702 31.4496C87.5402 31.4496 93.4502 27.9396 93.6302 21.9296C94.6702 22.4796 97.0402 22.7996 98.9102 22.5196C98.8802 22.5896 97.3902 27.9296 97.6602 28.9396C97.8102 29.5196 98.1302 30.1396 98.8802 30.1596C99.5002 30.1796 100.12 29.6496 100.76 29.0796C100.87 28.9796 106.6 22.7996 107.65 22.2196C108.45 21.7796 108.66 22.3696 108.67 22.6696C108.69 22.9796 108.72 26.1496 108.83 27.2596C108.93 28.2796 109.31 30.1096 112.13 30.1696C114.45 30.2096 117.09 28.1196 118.04 27.3996C119.11 26.5796 121.47 24.6096 123 22.4596C123.73 21.4296 123.44 20.8896 123.18 20.6996M90.9002 22.0496C90.6802 24.3096 89.3502 25.7196 88.4002 26.4996C87.4702 27.2596 85.5102 28.3096 84.2902 28.3496C83.1502 28.3896 83.1002 27.3596 83.2202 26.7596C83.5602 25.1096 85.9202 22.2096 88.2802 20.3896C89.0702 19.7796 89.6002 19.6496 89.8702 19.6896C90.4802 19.7796 91.0502 20.5996 90.9002 22.0496Z" fill="#787878"/>
                    <path d="M36.8495 36.77C35.9095 36.75 35.7495 35.67 36.0495 34.86C37.9295 29.01 38.7995 23.76 38.6395 19.25C38.4995 15.23 37.5195 11.85 35.7795 9.48C33.9095 6.92 31.2795 5.57 28.1595 5.57C22.4295 5.57 16.1995 10.04 11.3895 17.57C16.1695 13.89 21.2695 11.94 25.5595 11.94C28.6895 11.94 31.3495 12.86 33.3795 14.96C33.8695 15.47 34.0595 16.03 33.8795 16.46C33.7395 16.78 33.3195 17.25 32.2795 16.79C30.9095 16.18 29.4095 15.75 27.7995 15.81C17.1195 16.26 8.45953 26.07 4.89953 34.15C4.15953 35.84 3.27953 36.74 1.56953 36.67C-0.210469 36.59 -0.0604694 35.02 0.0495306 34.22C0.999531 27.17 5.14953 18.68 11.1395 11.51C17.1695 4.3 23.7695 0 28.8095 0C32.5795 0 35.9495 1.87 38.2895 5.26C40.4595 8.4 41.6795 12.69 41.8195 17.65C41.9695 23.03 40.8195 29.12 38.4895 35.28C38.0695 36.38 37.3395 36.77 36.8495 36.77Z" fill="#787878"/>
                    <path d="M69.7005 34.5595C75.5005 34.0395 77.0805 29.9495 77.2705 29.3295C77.4505 28.6995 77.6505 27.2595 77.1705 26.2795C75.8705 23.6195 73.0305 22.3095 73.5505 20.4495C73.9705 18.9395 77.7505 17.3495 79.1305 18.4095C79.4305 18.6395 79.6405 19.1395 79.7005 19.2295C80.0505 19.7495 80.5805 19.7195 80.7005 19.7195C81.4805 19.7195 82.3805 19.3095 82.5405 18.1495C82.7105 16.9395 82.1605 16.0695 81.8605 15.8195C80.1705 14.3695 76.7705 15.5095 75.8805 15.8795C72.6505 17.2195 71.3505 18.9595 71.1305 19.2195C70.6405 19.7895 70.1305 20.7395 70.0105 21.6595C69.6705 24.2695 72.9205 26.6695 73.5505 27.7995C74.1405 28.8495 73.4605 30.0895 73.1205 30.3895C71.8105 31.5495 69.4505 31.6795 68.0705 31.3295C67.9005 31.2895 67.6705 31.2095 67.4105 31.1395C67.0605 31.0395 66.6805 30.9695 66.4305 31.1295C65.9405 31.4395 65.8305 32.0695 65.9605 32.5495C66.3505 33.9695 68.2105 34.6995 69.7105 34.5695" fill="#787878"/>
                    <path d="M45.0204 31.0602C45.8104 31.0602 46.3504 30.4802 46.8504 29.5802C49.0404 25.6202 51.8304 20.9102 54.0804 20.0402C54.8604 19.7402 55.3904 20.4102 55.9604 20.3702C56.6604 20.3202 57.1704 19.9202 57.4204 18.9202C57.5404 18.4602 57.4604 17.9602 57.2104 17.5802C56.9604 17.1902 56.4304 16.8002 55.5904 16.8002C54.3004 16.8002 52.9504 17.7602 52.5704 18.0602C50.8304 19.4102 48.9004 21.4702 48.0104 22.5902C48.0104 22.5902 49.4304 18.8402 49.6704 18.1202C50.1104 16.8102 49.2204 16.2002 48.5004 16.2202C48.0804 16.2302 47.3304 16.3502 46.9404 17.2302C44.6704 22.4102 43.8304 27.3102 43.6004 28.9302C43.3604 30.6302 44.2504 31.0602 45.0204 31.0602Z" fill="#787878"/>
                    <path d="M64.2097 29.5902C64.6397 29.4302 66.4197 28.6702 67.3897 27.8402C68.0597 27.2702 68.7197 26.2102 68.3097 25.6702C67.9897 25.2602 67.4197 25.0602 66.1897 25.6802C65.6297 25.9602 63.3597 26.7702 62.9497 26.9002C62.3997 27.0702 61.2197 27.3802 60.9497 26.4502C60.4697 24.7902 62.2397 21.5202 63.5397 18.5902C63.9297 17.7102 64.1397 17.3202 63.9897 16.9702C63.8797 16.6902 63.4797 16.3202 62.3497 16.4402C62.0997 16.4602 60.9197 16.4502 59.9297 18.3802C59.4697 19.2602 58.3697 22.3502 58.3597 22.3802V22.4002C57.4297 25.0002 57.4197 26.5802 57.5397 27.2902C57.7097 28.3502 58.5397 29.9602 60.3397 30.2302C61.6697 30.4302 63.5197 29.8502 64.2197 29.5802" fill="#787878"/>
                    <path d="M65.6907 12.7902C66.9607 12.4802 68.4507 9.87016 67.6107 9.02016C67.2507 8.66016 66.7107 8.58016 66.1007 8.56016C65.3307 8.54016 64.7507 9.03016 64.3307 9.39016C64.3307 9.39016 63.0207 10.8802 63.0007 11.6402C63.0007 11.7902 62.8007 12.3402 63.6007 12.7102C63.8707 12.8402 64.6707 13.0402 65.7007 12.7902" fill="#787878"/>
                </g>
                <defs>
                    <clipPath id="clip0_379_3850">
                        <rect width="123.46" height="36.77" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
}